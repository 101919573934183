.select {
  border: 2px solid var(--Primary, #399);
  height: 56px;
  width: 100%;
  background: #ebf5f5;

  :global {
    .ant-select-selector {
      background: #ebf5f5 !important;
      padding-left: 20px !important;
      padding-right: 16px;
    }

    .ant-select-arrow svg path {
      fill: #339999;
    }

    .ant-select-arrow {
      inset-inline-end: 16px;
    }

    .ant-select-selection-item {
      div {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 17px;
      }
    }
  }
}

.options {
  :global {
    .ant-select-item-option-content svg {
      //display: none !important;
    }
  }
}
