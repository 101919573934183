.panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;


  @media screen and (max-width: 776px) {
flex-direction: column-reverse;
align-items: flex-start;
  }

}

.shipment,
.btn {
  color: var(--Primary, #399);
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 12px;
}

.shipment {
  @media screen and (max-width: 776px) {
 margin:  18px 0 0 0;
      }
}



.btn {
  :global {
    .ant-space-item {
      display: flex;
      align-items: center;

      svg {
        transition: all .3s;
        width: 24px;
        height: 24px;
      }

    }
  }

  .active {
    transform: rotate(180deg);
  }
}



.mainHeader {
  color: var(--Body-inverse, #FFF);
  font-size: 24px;
  font-weight: 700;
  padding: 12px 24px;
  background: var(--Primary, #399);

  @media screen and (max-width: 776px) {
    padding: 12px;
  }
}

.inner {
  padding: 24px;

  @media screen and (max-width: 776px) {
    padding: 12px;
  }
}

.header {
  height: 32px;
  padding: 0 12px;
  background: var(--Email-Background, #EBF5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--Body, #333);
  font-size: 18px;
  font-weight: 700;

  gap: 24px;

  :global {
    span {
      display: block;
      width: 50%;

      @media screen and (max-width: 776px) {
        width: 100%;
      }
    }
  }
}

.row {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media screen and (max-width: 776px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
  }
}

.col {
  flex-shrink: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  color: var(--Body, #333);
  font-size: 18px;
  font-weight: 400;

  @media screen and (max-width: 776px) {
    width: 100%;
  }
}

.desktop {
  @media screen and (max-width: 776px) {
    display: none !important;
  }
}

.mobileHeader {
  display: none;
  margin: 12px 0 12px -12px;
  width: calc(100% + 24px);

  @media screen and (max-width: 776px) {
    display: block;
  }
}

.mt {
  padding-top: 12px;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  background: var(--Email-Primary, #399);
  color: var(--Body-inverse, #FFF);
  font-size: 18px;
  font-weight: 400;
  margin-top: 12px;

  :global {
    span {
      font-weight: 700;
    }
  }
}

.info {
  text-align: right;
}