.item {
  display: flex;
  margin-bottom: 12px;
}

.itemTitle {
  font-weight: 700;
  margin-bottom: 8px;
}



.content {
  padding-left: 12px;

  :global {
    .ant-typography {
      display: block;
      line-height: 20px;
    }
  }
}

.text {
  font-size: 16px;
  margin-bottom: 6px;
}
