@import "src/resource/style/variables";


.dot {
  background: transparent !important;
  font-size: 20px;
}


.timeline {
  margin-top: 42px;
  :global {
    .ant-timeline-item {
      padding-bottom: 23px;
    }

    .ant-timeline-item-label {
      margin-top: -12px;
      padding-right: 20px;
    }

    .ant-timeline-item-content {
      inset-block-start: -17px;
      padding-left: 16px;
    }

    .ant-timeline-item-head {
      background:  #B4C6C6;
      border: 0px solid transparent;
    }

    .ant-timeline-item-tail {
      border-color: #B4C6C6;
      border-width: 1px;
    }

    .ant-timeline-item-head-custom {
      background: transparent !important;
    }
  }
}
