.file {
  display: flex;
  font-size: 16px !important;
  margin-top: 4px;
  color: #339999;

  :global {
    .anticon {
      padding-right: 11px;
      color: #339999;
    }
  }
}