@import 'src/resource/style/variables';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .footerInfo {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    .items{
      display: flex;
      flex-direction: column;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      color: #FFFFFF;
      text-align: center;
      a{
        color: #FFFFFF;
      }
    }
    .social{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      gap: 20px;
    }
    .linkBlock{
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-top: 30px;
      color: #CCCCCC;
      a{
        color: #CCCCCC;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 2px;
      }
    }

  }
}