
.label {
  padding-left: 40px;
}

.asLabel {
  top: 4px;
  font-size: 14px;
  padding: 0 0 0 42px;
}

.input {
  padding-left: 56px;
  
  :global {
    .ant-picker-suffix {
      position: absolute;
      top: -7px;
      left: -42px;
      width: 20px ;
      height: 20px;
      color: #339999;
      svg  {
        width: 100%;
        height: 100%;
      }
    }

    input {
      font-size: 18px !important;
    }
  }
}



