.floatLabel {
  position: relative;
  display: block;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 14px;
  transition: 0.2s ease all;
  z-index: 3;
  color: var(--Placeholder, #616161);
}

.asPlaceholder {
  font-size: 18px;
}

.asLabel {
  top: 4px;
  font-size: 14px;
  padding: 0 4px;
  margin-left: -3px;
}


.input {
  border: 1px solid transparent !important;
  background: #ebf5f5 !important;
  height: 56px !important;
  border-bottom: 1px solid var(--Primary, #399) !important;
  padding-top: 26px;
  width: 100%;
  padding-left: 16px;
}

