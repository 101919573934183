.input {
  padding-top: 16px !important;

  &.ant-input-number-group-wrapper-disabled {
    opacity: 0.2 !important; 
  }

  :global{
   

    input {
      padding-left: 0 !important;
    }
    .ant-input-number-handler-wrap {
      opacity: 1 !important;
      margin-right: 15px;
    }
    .ant-input-number-handler {
      border: none !important;
      height: 50% !important;
      .anticon {
        color: #339999;
      }
    }

    .ant-input-number-group-addon {
      font-size: 14px;
      top: -8px;
      left: -2px;
    }
  }
}

