.container {
  border: 1px solid #B4C6C6;
}

.header {
  padding: 26px 0;

  @media screen and (max-width: 767px) {
    padding: 14px 0;
  }

  :global {
    .ant-typography {
      margin: 0 !important;
    }
  }
}

.box {
  padding: 0 36px 24px 36px;
  border-bottom: 1px solid #B4C6C6;

  @media screen and (max-width: 767px) {
    padding: 0 20px 18px 20px;
  }

  :global {
    span.ant-typography {
      font-size: 18px;
      line-height: 1.4;

      strong {
        font-weight: 700;
      }
    }

    .ant-col {
      padding: 0 12px;

      @media screen and (max-width: 767px) {
        padding: 0 8px;
      }
    }
  }
}

.icon {
  font-size: 17px;
  margin-top: -3px;
  color: #339999;

  :global {
    svg {
      overflow: visible;
    }
  }
}

.list {
  margin-left: 16px;
}

.listTitle {
  color: #616161;
  font-size: 14px !important;
}

.capitalize {
  text-transform: capitalize;
}

.listItem {
  margin-top: 6px;
}

.qty {
  width: 66px;
  padding-right: 6px;
  display: block;
}

.productRow {
  border-bottom: 1px solid #B4C6C6;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
}

.rowBottom {
  padding-top: 12px !important;
  margin-top: 12px;
  border-top: 1px solid #B4C6C6;
}

.capitalize {
  text-transform: capitalize;
}

.notesTitle {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px !important;
}

.textarea {
  padding: 8px 12px;
  border: 0px solid transparent;
  border-bottom: 1px solid var(--Primary, #399);
  background: var(--Bg-Light, #EBF5F5);
  margin-bottom: 24px;

  &::placeholder {
    color: #616161;
  }
}

.btn {
  margin-top: 52px;
}

@media screen and (max-width: 520px) {
  .billingOption{
    flex-direction: column;
    .billingOptionCol{
      max-width: 100% !important;
    }
  }
}

.packageItem {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;

  .packageColumn {
    font-size: 16px;
    width: 50%; //min-width: 50%;

    @media screen and (max-width: 767px) {
      width: unset;
      min-width: 250px;
    }

    .columnTitle {
      font-size: 18px;
      margin: 0;
      font-weight: bold;
    }

    ul {
      margin: 0;
      //list-style: none;
      padding-inline-start: 10px;
    }
    .packageListItem {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      list-style-position: inside;
    }

    // Package values:
    .row {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
