.wrapper {
  padding: 15px 16px;
  background: var(--Bg-Light, #EBF5F5);
  min-height: 56px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 18px;

  :global {
    button {
      margin-right: 16px;
    }
  }
}

.noClickable {
  pointer-events: none !important;
}