.input {
  // padding-top: 16px !important;
  padding-top: 0px !important;

  :global{
    input {
      padding-top: 23px !important;
      padding-left: 0;
    }
  }
}

.inner {
  margin-top: -3px;
  :global {
    .ant-select-arrow .anticon {
      color: #339999;
    }
  }
}
