.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100%;
  background: #fff;
  position: relative;
}
.header {
  position: sticky;
  z-index: 4;
  top: 0;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  background-color: #585858;
}
.footer {
  z-index: 2;
  height: 460px;
  background-color: #585858;
  min-width: 100%;
  background-image: url('../../../../public/media/sephyre-feather-footer.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  @media (max-width: 768px){
    height: 550px;
  }
}