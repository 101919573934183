.radio {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 600px) {
    display: block;
  }

  :global {
    .ant-radio-button-wrapper {
      width: calc(25% - 2px); // calc(20% - 2px);
      padding: 12px 16px;
      height: 86px;
      border: 2px #ebf5f5;
      background: var(--Bg-Light, #ebf5f5);

      &::before {
        display: none;
      }

      @media screen and (max-width: 766px) {
        padding: 6px 8px;
        height: 66px;
      }

      @media screen and (max-width: 600px) {
        display: block;
        width: 100%;
        padding: 12px 16px;
        height: 50px;
        margin-top: 2px;
      }

      &.ant-radio-button-wrapper-checked {
        border: 2px solid var(--Primary, #399);
        background: var(--Bg-Main, #fff);
        box-shadow: none;

        @media screen and (max-width: 600px) {
          // border: 1x solid var(--Primary, #399);
        }
      }
    }

    @media screen and (max-width: 600px) {
      .ant-radio-button {
        z-index: 1;
        width: 20px;
        height: 20px;
        display: block;
        right: 23px;
        left: auto;
        top: -5px;
        bottom: auto;

        @supports (-webkit-appearance: none) {
            top: 13px;
        }
        @supports (position: -webkit-sticky) {
            top: -5px;
        }

        input {
          width: 0;
          height: 0;
          opacity: 1;
          box-shadow: none !important;
          outline: none !important;

          &:after {
            width: 20px;
            height: 20px;
            border-radius: 15px;
            top: -2px;
            left: 0px;
            position: relative;
            background-color: #EBF5F5;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 2px solid #B4C6C6;
          }

          &:checked:after {
            background-color: #B4C6C6;
          }
        }
      }
    }

  }
}

.radioWrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    width: 24px;
    height: 24px;
    overflow: visible;
    margin-left: -6px;

    @media screen and (max-width: 766px) {
      width: 20px;
      height: 20px;
    }

    @media screen and (max-width: 600px) {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
    font-size: 18px;
  }
}
