// generate by https://transfonter.org/

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro/SourceSansPro-Regular.woff2') format('woff2'),
        url('SourceSansPro/SourceSansPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro/SourceSansPro-Bold.woff2') format('woff2'),
        url('SourceSansPro/SourceSansPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro/SourceSansPro-Bold.woff2') format('woff2'),
        url('SourceSansPro/SourceSansPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro/SourceSansPro-Semibold.woff2') format('woff2'),
        url('SourceSansPro/SourceSansPro-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro/SourceSansPro-Light.woff2') format('woff2'),
        url('SourceSansPro/SourceSansPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}