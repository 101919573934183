.hint {
  font-size: 24px;
  transform: translateY(-9px);
  font-size: 20px;
  padding-right: 5px;
  color: #339999;
}

.hintInput {
  padding-top: 16px !important;
  padding-left: 6px !important;
}

.disabled {
  :global {
    .ant-input-disabled {
      background: #B4C6C6 !important;
      color: #333 !important;
    }
  }
}