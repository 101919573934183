.address {
  color: var(--Placeholder, #616161);
  line-height: 140%;
}

.status {
  text-transform: capitalize;
  font-size: 18px;
  line-height: 150%;
}
