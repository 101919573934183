@import "src/resource/style/variables";

.item {
  border: 1px solid var(--Line, #B4C6C6);
  display: flex;
  padding: 12px;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-top: 52px;
  height: 80px;


  @media screen and (max-width: 992px) {
    height: auto;
    gap: 12px;
    flex-direction: column;
  }
}

.top {
  display: flex;
  gap: 24px;
  align-items: center;


  @media screen and (max-width: 500px) {
    width: 100%;
  }

}

.img {
  width: 89px;
  height: 56px;
  background: #EBF5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  :global {
    svg {
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.number {
  font-size: 24px;
  font-weight: 700;
  line-height: 132%;


  .on_the_way {
    color: var(--colorInfoBase, #1677FF);
    border: 1px solid var(--colorInfoBorder, #91CAFF);
    background: var(--colorInfoBg, #E6F4FF);
  }

  .delivered {
    color: #399;
    border: 1px solid #7EBFBF;
    background: var(--Bg-Light, #EBF5F5);
  }

  .alert {
    color: var(--colorWarningBase, #FAAD14);
    border: 1px solid var(--colorWarningBorder, #FFE58F);
    background: var(--colorWarningBg, #FFFBE6);
  }

  .not_delivered {
    color: var(--colorErrorBase, #FF4D4F);
    border: 1px solid var(--colorErrorBorder, #FFA39E);
    background: var(--colorErrorBg, #FFF1F0);
  }

  .expired {
    color: var(--colorErrorBase, #FF4D4F);
    border: 1px solid var(--colorErrorBorder, #FFA39E);
    background: var(--colorErrorBg, #FFF1F0);
  }

  :global {
    div {
      display: block;
      border-radius: 4px;
      border: 1px solid var(--colorBorder, rgba(0, 0, 0, 0.15));
      background: var(--colorFillAlter, rgba(0, 0, 0, 0.02));
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000E0;
      font-size: 12px;
      width: max-content;
      padding: 1px 8px;
      height: 22px;
      font-weight: 400;

      svg {
        font-size: 12px;
        margin-right: 4px;
      }
    }
  }
}

.directions {
  display: flex;
  align-items: center;
  gap: 12px;
}


.origin {
  text-align: right;
}

.direct {
  color: var(--Placeholder, #616161);
  line-height: 140%;
  display: flex;
  flex-direction: column;

  :global {
    span {
      line-height: 142%;
      font-size: 18px;
      display: block;
    }
  }
}

.icon {
  font-size: 24px;

  :global {
    svg {
      overflow: visible;
    }
  }
}

.refresh {
  color: var(--Primary, #399);
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  :global {
    svg {
      font-size: 24px;
      margin-right: 8px;
    }
  }
}