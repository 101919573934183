.wrapper {
  min-height: 56px;
  background: #EBF5F5;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 0 16px;

  :global {
    .ant-upload-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }

    .ant-upload-list-item {
      display: none !important;
    }

    .ant-btn {
      display: flex;
    }
  }
}

.label {
  position: absolute;
  top: 13px;
  left: 13px;
  font-size: 18px;
  color: #616161;
  transition: all .3s;

  &.isValue {
    top: 4px;
    font-size: 14px;
    left: 18px;
  }
}

.name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 21px;

  max-width: calc(100% - 94px);

  :global {


    svg {
      color: #339999;
    }
  }
}

.value {
  margin-top: 20px;
  margin-left: 2px;
}
