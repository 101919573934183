@import 'src/resource/style/variables';

.header{
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-around;
  background-color: #585858;
  transition: all .5s;
  @media (max-width: 1440px){
    transition: all .5s;
    width: 100%;
    justify-content: space-between;
  }
  a{
    display: flex;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 10px;
  }
  a:hover{
    color: #339999;
  }
  .logo{
    height: 40px;
  }
  .links{
    display: flex;
    @media (max-width: 840px) {
      display: none;
    }
  }
  .menu{
    .menuButton{
      display: flex;
    }
    @media (min-width: 841px){
      display: none;
    }

  }
}
.menuItems{
  display: flex;
  flex-direction: column;
  gap: 30px;
  a{
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 700;
    text-transform: uppercase;
  }
}