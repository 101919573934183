@import "src/resource/style/variables";

$border-bold-styles: 3px solid $primary-green-color;

.tabLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  > svg {
    font-size: 24px;
  }
}

.customTabBar {
  margin-top: 16px !important;

  :global {
    .ant-tabs-nav-list, .ant-tabs-tab {
      flex-grow: 0.99; // With 1 tab component hides last item in '...' button
    }
    .ant-tabs-nav-list {
      height: 56px;
      padding-bottom: 1px;
      box-sizing: border-box;
    }

    .ant-tabs-tab {
      width: 50%; // MB remove

      border: 1px solid $primary-green-color;
      justify-content: center;
      margin: 0 !important;

      color: $primary-green-color;
      font-size: 17px;

      transition: 0.1s ease-in-out;
    }

    .ant-tabs-tab-active {
      border: $border-bold-styles;
      //border-top: $border-bold-styles;
      //border-bottom: $border-bold-styles;
    }

    // Hide default 'active' highlight
    .ant-tabs-ink-bar {
      display: none;
      background: transparent;
    }
  }
}
