.select {
  padding-left: 0 !important;
  padding-top: 0 !important;

  :global {
    .ant-select-selection-search-input {
      margin: 0 !important;
      padding: 0 !important;
    }

    .ant-select-selection-item {
      padding-left: 6px !important;
      padding-top: 0 !important;
      margin: 0;
      margin-top: 0 !important;
      background: transparent !important;
    }

    .ant-select-selection-overflow-item {
      padding-top: 20px;
      padding-left: 6px;
    }

    .ant-select-selection-item-remove {
      display: none !important;
    }
  }
}