.label {
    font-size: 18px;
    display: flex;
    align-items: center;
    svg {
        overflow: visible;
margin-right: 16px;
        width: 20px;
        height: 20px;
    }
}