.select {
  padding-left: 0 !important;
padding-top: 0 !important;
  :global {
    .ant-select-selection-item {
      padding-top: 20px !important;
      padding-left: 6px !important;
    }
    .ant-select-selection-search-input {
      margin-top: 18px !important;
      padding-left: 6px !important;
    }
  }
}
