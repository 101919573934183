@import "src/resource/style/variables";

.wrapper {
  max-width: 984px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-top: 0 !important;
  font-size: 48px !important;
  margin-bottom: 24px !important;

  @media screen and (max-width: 767px) {
font-size: 38px !important;
  }
}

.text {
  margin-bottom: 0 !important;
  font-size: 18px;
  text-align: center;
}

.subtitle {
  margin-top: 40px !important;
  @media screen and (max-width: 767px) {
font-size: 20px;
margin-top: 32px !important;
  }

}

.items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;


  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 0;
  }
}

.item {
  flex: 1; // width: 16.6%;
  padding: 0 8px;
  text-align: center;
  position: relative;

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    text-align: left;
    max-width: 200px;
    width: 100%;
    margin: 0 auto 8px auto;

    &:before, &:after {
      display: none;
    }
  }

  &:before, &:after {
    z-index: 0;
    content: '';
    position: absolute;
    top: 28px;
    width: 55%;
    height: 1px;
    background: #ccc; // #EBF5F5
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  &.leftLineOnly:after {
    display: none;
  }

  &.rightLineOnly:before {
    display: none;
  }
}

.icon {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background: #EBF5F5;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 15px;

  z-index: 1;
  position: inherit;

  @media screen and (max-width: 767px) {
    width: 32px;
    height: 32px;
    margin: 0 12px 0 0;
  }

  :global {
    svg {
      overflow: visible;
      font-size: 22px;

      @media screen and (max-width: 767px) {
        font-size: 17px;
      }
    }

  }
}
