.header {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;

  @media screen and (max-width: 776px) {
    overflow-y: auto;
    max-width: min(962px, calc(100vw - (16px * 2) - (20px *2) - 24px - 2px));
    padding-right: 28px;
  }
}

.count {
  max-width: 60px;
  width: 100%;
  padding-right: 8px;
  white-space: nowrap;
}

.headerKey {
  max-width: 56px;
  width: 100%;
  font-weight: 700;
  padding-right: 8px;
}

.headerName {
  max-width: 193px;
  width: 100%;
  padding-right: 8px;
  white-space: nowrap;
}

.descriptions {
  width: 100%;
  max-width: 120px;
}

.itemRight {
  text-align: right;
  width: 100%;
  padding-right: 26px;
  padding-left: 16px;
}

.remove {
  padding-right: 30px;

  @media screen and (max-width: 1160px) {
    padding-right: 6px;
  }

  :global {
    .anticon {
      font-size: 18px;
      color: #339999;
    }
  }
}

.collapse {
  background: transparent;
  border: 1px solid #b4c6c6;
}

.btnWrap {
  padding: 12px 36px;
  border: 1px solid #b4c6c6;
  border-top: 0px solid transparent;
}

.panel {
  border-bottom: 1px solid #b4c6c6 !important;

  &:last-child {
    border-bottom: 1px solid transparent !important;
  }

  :global {
    .ant-collapse-header {
      height: 76px;
      padding-right: 20px;
      align-items: center !important;
    }

    .ant-collapse-content {
      border-top: none;
    }

    .ant-collapse-content-box {
      padding: 0 36px 36px 36px !important;

      @media screen and (max-width: 1160px) {
        padding: 0 20px 20px 20px !important;
      }
    }
  }
}

.review {
  border: 1px solid #b4c6c6;
  border-top: 0 solid transparent;
  display: flex;
  justify-content: space-between;

  padding: 12px 36px;
  font-size: 18px;

  :global {
    span {
      font-weight: 700;
    }
  }
}

.reviewItem {
  text-align: right;
}

.offset {
  margin-bottom: 28px !important;
}

.rowToRight {
  display: flex;
  gap: 16px;
  justify-content: end;
  flex-wrap: wrap;
}
