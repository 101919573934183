.wrapper {
  min-height: 100vh;
  width: 260px;
  position: relative;
  flex-shrink: 0;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.container {
  z-index: 0;
  width: 260px;
  left: 0;
  height: 100%;

  padding: 48px 20px 24px 20px;
  background: var(--Bg-Light, #ebf5f5);
  overflow-y: auto;
}

.title {
  margin-top: 0;
}

.icon {
  color: #339999;
  font-size: 18px;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  flex-shrink: 0;
  background: var(--Bg-Hover, #cee7e7);
}


.items {
  margin-top: 24px;
}