@import "src/resource/style/variables";

.wrapper {
  max-width: calc(984px + 40px);
  padding: 0 20px;
  width: 100%;
  margin: 48px auto 48px auto;

  :global {
    .ant-btn.ant-btn-lg {
      height: 56px;
      box-shadow: none;
      font-weight: 600;
    }

    .ant-form-item-explain {
      position: relative;
    }

    .ant-form-item-explain-error {
      padding-left: 16px;
      position: absolute;
    }

  }
}

.title {
  font-size: 48px !important;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 38px !important;
  }
}

.form {
  display: flex;
  margin-top: 40px;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 767px) {
    margin-top: 32px;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 28px;
  }

  :global {
    .ant-form-item {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.btn {
  @media screen and (max-width: 550px) {
    width: 100%;
  }
}

.input {
  border: 1px solid transparent !important;
  background: #ebf5f5 !important;
  height: 56px !important;
  border-bottom: 1px solid var(--Primary, #399) !important;
  width: 100%;
  padding-left: 16px !important;

  &::placeholder {
    color: #616161 !important;
  }
}

.contactInfo {
  margin-top: 52px;

  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
}

.infoText {
  font-size: 18px;
  display: block;
}

.notFound {
  color: var(--Body, #333);
  font-size: 18px;
  font-weight: 400;
}

.otherText {
  font-size: 18px;
  font-weight: 400;
  line-height: 142%;
margin-top: 24px;
  :global {
    a {
      color: var(--Primary, #399);
    }

  }
}