.productsWrapper {
  display: grid;
  grid-template-columns: 1fr 40px;
  width: 100%;
  min-height: 56px;
  align-items: center;
  background: #ebf5f5 !important;

  gap: 4px;
  border-bottom: 1px solid var(--Primary, #399) !important;

  &.error {
    border-color: #FF4D4F;
  }

  .mainContent {
    padding: 4px 11px;
    display: flex;
    flex-wrap: wrap;
    white-space: normal;
    gap: 4px;
  }

  .addonAfter {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .productItem {
    display: flex;
    gap: 6px;
    background: #ededed;
    padding: 2px 6px;
    border-radius: 6px;
  }

  .deleteIcon {
    font-size: 14px;
    cursor: pointer;
    padding: 0 3px;
    border-radius: 3px;

    &:hover {
      background-color: lightgrey;
    }
  }
}

.tableContainer {
  overflow-y: auto;
}
