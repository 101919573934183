@import "src/resource/style/variables";

.wrapper {
  max-width: 1060px;
  padding: 0 20px;
  width: 100%;
  margin: 48px auto 48px auto;

  :global {

    .ant-input-number-group-wrapper-disabled {
      background: #B4C6C6 !important;
      input {
        color: #333;
      }
    }


    .ant-form-item-explain {
      position: relative;
    }
    .ant-form-item-explain-error {
      padding-left: 16px;
      position: absolute;
    }

    .invisible {
      width: 100%;
        > span {
          width: 100%;
          display: block;
          padding: 0;
        }
        .ant-checkbox  {
          position: absolute;
          opacity: 0;
          pointer-events: none;
        }
  
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-btn.ant-btn-lg {
      height: 56px;
      box-shadow: none;
    }
    .ant-btn.ant-btn-default {
      border-width: 2px;
      border-color: #399;
      font-size: 17px;

      span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media screen and (max-width: 767px) {
      .ant-btn-primary {
        width: 100%;
        display: block;
      }
    }
  }
}

.stepsWrap {
  width: 100%;
  overflow: hidden;
  padding-top: 10px;
}

.steps {
  @media screen and (max-width: 600px) {
    max-width: 360px;
    margin: 0 auto;
    transform: translateX(-28px);
  }

  :global {
    .ant-steps-item {
      @media screen and (max-width: 600px) {
        width: 25%;
        display: block;
        flex: 0;
      }
    }

    .ant-steps-icon-dot {
      transition: none !important;
    }

    .ant-steps-item-active {
      .ant-steps-icon {
        top: -2.5px !important;
        border: 2px solid #399;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
      }

      .ant-steps-icon-dot {
        width: 8px !important;
        height: 8px !important;
      }
    }

    .ant-steps-item-wait {
      .ant-steps-icon-dot {
        width: 10px !important;
        height: 10px !important;
        border: 1px solid #ccc;
        background: #fff !important;
      }
    }

    .ant-steps-item-finish {
      .ant-steps-icon {
        top: -5.5px !important;

        .ant-steps-icon-dot {
          position: relative;
          width: 20px;
          height: 20px;

          &::before {
            width: 11px;
            height: 18px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' viewBox='0 0 11 8' fill='none'%3E%3Cpath d='M4.17 5.76868L1.58968 3.18836C1.29715 2.89583 0.822853 2.89583 0.530318 3.18836C0.237535 3.48115 0.237819 3.95593 0.530954 4.24836L3.46289 7.17327C3.85355 7.56299 4.48607 7.56261 4.87626 7.17242L10.4687 1.58001C10.761 1.2877 10.7599 0.81343 10.4662 0.522512C10.1744 0.233537 9.70403 0.234652 9.41367 0.525006L4.17 5.76868Z' fill='white'/%3E%3C/svg%3E");
          }
        }
      }
    }

    .ant-steps-item-tail {
      top: 3.5px !important;
      margin-inline: 62px 0 !important;

      &:after {
        width: 100% !important;
        height: 1px !important;
      }
    }

    .ant-steps-item-finish {
      &:after {
        height: 2px !important;
      }
    }
  }
}